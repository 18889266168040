:root,
[data-theme="dark"] {
  /* Background */
  --color-background-gradient: linear-gradient(120deg, rgba(56, 51, 61, 1) 0%, rgba(52, 48, 55, 1) 100%);
  --color-background: #343037;

  /* Foreground */
  --color-foreground: #F3EDF3;

  /* Elevation */
  --color-elevation: #413A46;

  /* Social Button */
  --google-button: #4285f4;
  --google-button-text: #fff;

  /* Alerts */
  --color-positive-200: #5cd2c0;
  --color-positive-100: #d1f2ed;
  --color-positive-300: #214c45;

  --color-negative-200: #d25c5c;
  --color-negative-100: #f2d1d1;
  --color-negative-300: #4c2121;

  --color-warning-200: #d2b15c;
  --color-warning-100: #f2e9d1;
  --color-warning-300: #4c4021;
}

[data-theme="black"] {
  /* Background */
  --color-background-gradient: #000000;
  --color-background: #000000;
}

[data-theme="light"] {
  /* Background */
  --color-background-gradient: linear-gradient(120deg, rgba(238, 234, 243, 1) 0%, rgba(229, 225, 236, 1) 100%);
  /* --color-background: #F3EDF3; */
  --color-background: #E8E5EE;

  /* Foreground */
  --color-foreground: #343037;

  /* Elevation */
  /* --color-elevation: #F7F6F9; */
  --color-elevation: #DFD8E9;

  /* Social Button */
  --google-button: #fff;
  --google-button-text: rgba(0, 0, 0, .54);

  /* Alerts */
  --color-positive-200: #5cd2c0;
  --color-positive-100: #214c45;
  --color-positive-300: #d1f2ed;

  --color-negative-200: #d25c5c;
  --color-negative-100: #4c2121;
  --color-negative-300: #f2d1d1;

  --color-warning-200: #d2b15c;
  --color-warning-100: #4c4021;
  --color-warning-300: #f2e9d1;
}
