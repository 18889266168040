@import 'common/styles/theme/_theme.scss';

.dashboard {
  .controls {
    position: sticky;
    top: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: $space-s $space-m;
    margin-bottom: $space-xxxl;
    background: $color-background;
    z-index: 5;

    .title {
      font-weight: $type-weight-bold;
      font-size: 24px;
      letter-spacing: -0.4px;
    }

    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 320px;
      padding: 12px 12px 12px 16px;
      background-color: $color-elevation;
      border: 1px solid transparent;
      border-radius: 16px;
      transition: all 0.4s ease;

      &:focus-within {
        border: 1px solid $color-accent-primary;
        box-shadow: 0 0 80px rgba($color-accent-primary, 0.4);
      }

      .search {
        padding: unset;
        width: 100%;
        background: none;
        outline: none;
        border: none;
        font-size: 16px;
        letter-spacing: -0.2px;
        transition: unset;

        &:focus {
          border: unset;
          border-radius: unset;
          box-shadow: unset;
        }
      }
    }
  }

  .empty-recording-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0;

    img {
      width: 200px;
      margin-bottom: 24px;
    }

    .empty-title {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.4px;
      font-weight: $type-weight-bold;
      color: $color-accent-primary;
      margin-bottom: 12px;
    }
  }

  .recording-list {
    .recording-list-set {
      margin-bottom: 64px;

      .set-title {
        display: block;
        font-weight: $type-weight-bold;
        line-height: 24px;
        padding: 0 16px;
        opacity: 0.56;
        margin-bottom: 12px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .controls {
      flex-wrap: wrap;
      margin-bottom: 24px;
      padding: 8px;
      .search-box {
        width: calc(100% - 24px);
        margin-top: 8px;
      }
    }
  }
}
