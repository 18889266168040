@import 'common/styles/theme/_theme.scss';

.speech-block {
  margin-bottom: $space-xl;
  padding-left: 18px;

  .speech-details {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80%;

    &:before {
      content: '✎';
      position: absolute;
      top: 10px;
      left: -$space-xl;
      font-size: 14px;
      line-height: 24px;
      color: $color-accent-primary;
      opacity: 0;
      transition: all 0.4s ease;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .speaker {
      font-weight: $type-weight-bold;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      background: transparent;
      padding: $space-xs $space-s;
      border: 1px dashed transparent;
      border-radius: 16px;
      transition: all 0.4s ease;
      cursor: text;

      &:hover {
        border-left-color: $color-accent-primary;
      }

      &:focus {
        border-color: $color-accent-primary;
        background-color: $color-elevation;
        box-shadow: none;
      }
    }

    .metadata {
      opacity: 0.8;

      .datetime {
        line-height: 24px;
        &:hover {
          color: $color-accent-primary;
          cursor: pointer;
        }
      }
    }
  }

  .speech-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;

    &:before {
      content: '✎';
      position: absolute;
      top: 13px;
      left: -$space-xl;
      font-size: 14px;
      line-height: 24px;
      color: $color-accent-primary;
      opacity: 0;
      transition: all 0.4s ease;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .speech {
      margin: 0;
      line-height: 24px;
      max-width: 80%;
      background: transparent;
      padding: $space-s;
      width: 100%;
      resize: none;
      font-size: 14px;
      border: 1px dashed transparent;
      border-radius: 16px;
      transition: all 0.4s ease;
      cursor: text;

      &:hover {
        border-left-color: $color-accent-primary;
      }

      &:focus {
        border-color: $color-accent-primary;
        background-color: $color-elevation;
        box-shadow: none;
      }
    }

    .mark-as-starred {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    .speech-details {
      .speaker {
        padding: 6px;
      }
    }

    .speech-content {
      .speech {
        max-width: 100%;
        padding: 6px;
      }
    }
  }
}
