@import 'common/styles/theme/_theme.scss';

.footer {
  .footer-wrapper {
    @include section-wrapper;
    display: flex;
    justify-content: flex-end;
    gap: $space-m;
  }

  &.stacked {
    padding-left: $space-l;

    .footer-wrapper {
      flex-direction: column;
      gap: $space-xs;
    }
  }

  @media screen and (max-width: 480px) {
    .footer-wrapper {
      max-width: unset;
      flex-wrap: wrap;
    }
  }
}
