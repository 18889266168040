@import "common/styles/theme/_theme.scss";

.transcription-status {
    @include grid-template;

    .transcription-details {
        display: flex;
        flex-direction: column;
        row-gap: $space-s;
        text-align: center;
        align-items: center;

        .transcription-title {
            font-size: 32px;
            font-weight: $type-weight-bold;
            letter-spacing: -1px;
            color: $color-accent-primary;
        }

        .transcription-description {
          margin-bottom: 24px;
        }
    }
}
