@import "common/styles/theme/_theme.scss";

.recording-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  // margin-bottom: 12px;
  border-radius: 16px;
  background-color: transparent;
  transition: background-color 0.4s ease;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $color-foreground;
    opacity: 0.08;
  }

  &:hover {
    background-color: $color-elevation;
  }

  .details {

    .title {
      font-weight: $type-weight-bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .metadata {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      vertical-align: baseline;
    }

    .job-status {
      font-size: 11px;
      line-height: 20px;
      margin-left: $space-s;
      font-weight: $type-weight-bold;
      padding: 0px 8px;
      border: 1px solid transparent;
      border-radius: 8px;
      vertical-align: baseline;

      &.completed {
        display: none;
      }

      &.not_submitted {
        background-color: $color-warning-300;
        color: $color-warning-100;
        border-color: $color-warning-100;
      }

      &.in_progress {
        background-color: $color-positive-300;
        color: $color-positive-100;
        border-color: $color-positive-100;
      }

      &.failed {
        background-color: $color-negative-300;
        color: $color-negative-100;
        border-color: $color-negative-100;
      }
    }
  }

  .calls-to-action {
    display: flex;
    flex-direction: row;
    .item {
      background-color: $color-elevation;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border: 1px dashed $color-foreground;
      border-radius: 16px;
      background-color: $color-elevation;
      transition: all 0.4s ease;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0);
      margin: 3px;

      &:hover {
        background-color: $color-accent-secondary;
      }

      .transcript-icon {
        transition: all 0.4s ease;
      }
    }
  }
}
