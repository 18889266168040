@import 'common/styles/theme/_theme.scss';

.site {
  // background: url("assets/images/page-background.png");
  // width: 100vw; // Fix for jumping scrollbar
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;

  .content {
    @include section-wrapper;
    display: grid;
    grid-template-columns: 240px auto;
    column-gap: 80px;
    padding-top: 64px;
  }

  @media screen and (max-width: 480px) {
    padding: 12px;
    padding-top: 60px;
    // padding-bottom: 40px;
    overflow-wrap: anywhere;

    .content {
      grid-template-columns: unset;
      max-width: unset;
      padding-top: 32px;
    }
  }
}

body {
  box-sizing: border-box;

  .root {
    width: 100vw; // Fix for jumping scrollbar
  }
}
