@import 'common/styles/theme/_theme.scss';

.site-navigation {
  position: sticky;
  top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 72vh;

  .navigation-links-wrapper {
    display: flex;
    flex-direction: column;
    align-self: start;
    row-gap: 48px;
    max-width: 240px;

    .upload {
      display: flex;
      flex-direction: column;
      padding-left: 16px;

      &:hover {
        .upload-icon {
          path {
            fill: $color-accent-primary;
            stroke: $color-accent-primary;
          }
        }
      }

      .upload-icon {
        width: 32px;

        path {
          fill: $color-foreground;
          transition: all 0.4s ease;
        }
      }

      .label {
        font-size: 36px;
        letter-spacing: -1px;
        margin-top: 12px;
      }
    }

    .divider {
      width: 80px;
      border-bottom: 1px dashed $color-foreground;
      margin-left: 16px;
    }

    .organization {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .item {
        display: flex;
        column-gap: 12px;
        align-items: center;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 16px;
        border-left: 2px solid transparent;
        background-color: transparent;
        transition: all 0.2s ease;

        svg {
          path {
            transition: all 0.2s ease;
          }
        }

        &:hover {
          background-color: $color-foreground;
          color: $color-elevation;

          svg {
            path {
              stroke: $color-elevation;
            }
          }
        }

        &.selected {
          background-color: $color-elevation;
          color: $color-foreground;
          border-left: 2px solid $color-accent-primary;

          svg {
            path {
              stroke: $color-foreground;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    position: relative;
    top: 0;
    max-width: none;
    row-gap: 36px;
    margin-bottom: 12px;
    height: auto;

    .navigation-links-wrapper {
      row-gap: 16px;
      max-width: unset;
      width: 100%;
      .upload {
        align-items: center;
        padding: 0;
        text-align: center;
      }

      .divider {
        width: 90%;
        margin: 0 5%;
      }

      .organization {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;

        .item {
          padding: 8px 6px;
          column-gap: 6px;
        }
      }
    }
  }
}
