@import 'common/styles/theme/_theme.scss';

.audio-player {
  box-sizing: border-box;
  background-color: $color-elevation;
  border-radius: 24px;
  padding: 32px;
  margin-bottom: 80px;
  display: grid;
  justify-content: stretch;
  align-items: center;

  span {
    opacity: 0.56;
  }

  audio {
    width: 100%;

    &::-webkit-media-controls-panel {
      // background-color: $color-elevation;
    }

    &::-webkit-media-controls-play-button {
      background-color: $color-accent-primary;
      padding: 8px;
      border-radius: 48px;
      color: white;
    }
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
    padding: 8px;
  }
}

/*
audio::-webkit-media-controls-panel
audio::-webkit-media-controls-mute-button
audio::-webkit-media-controls-play-button
audio::-webkit-media-controls-timeline-container
audio::-webkit-media-controls-current-time-display
audio::-webkit-media-controls-time-remaining-display
audio::-webkit-media-controls-timeline
audio::-webkit-media-controls-volume-slider-container
audio::-webkit-media-controls-volume-slider
audio::-webkit-media-controls-seek-back-button
audio::-webkit-media-controls-seek-forward-button
audio::-webkit-media-controls-fullscreen-button
audio::-webkit-media-controls-rewind-button
audio::-webkit-media-controls-return-to-realtime-button
audio::-webkit-media-controls-toggle-closed-captions-button
*/