@import 'common/styles/theme/_theme.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;

  .header-wrapper {
    @include section-wrapper;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    transition: all 0.3s ease;

    .logo {
      padding-left: 16px;

      svg {
        path:not(:last-child) {
          fill: $color-foreground;
        }
      }
      &::after {
        content: 'alpha';
        font-size: 11px;
        color: $color-accent-primary;
        margin-left: 2px;
        font-weight: 500;
        vertical-align: top;
      }
    }

    .action-bar {
      .account {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        border-radius: 16px;
        background-color: transparent;
        transition: background-color 0.4s ease;

        &:hover {
          background-color: $color-elevation;
          color: $color-foreground;
        }

        .label {
          margin-right: 12px;
        }

        .avatar {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &.sticky {
    background-color: $color-elevation;

    .header-wrapper {
      padding: 16px 0;
    }
  }

  @media screen and (max-width: 480px) {
    .header-wrapper {
      max-width: unset;
      padding: 16px;
      flex-wrap: wrap;

      .logo {
        padding: 0;
      }

      .action-bar {
        .account {
          padding: 0;
        }
      }
    }

    &.sticky {
      .header-wrapper {
        padding: 16px;
      }
    }
  }
}
