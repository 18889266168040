@import "common/styles/theme/_theme.scss";

.not-found-container {
    @include grid-template;
    @include section-wrapper;
    max-width: 1000px;

    .not-found-details {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-bottom: 24px;
        text-align: center;
        align-items: center;

        .not-found-title {
            font-size: 36px;
            font-weight: $type-weight-bold;
            letter-spacing: -1px;
            color: $color-accent-secondary;
        }

        .not-found-description {
            font-weight: $type-weight-bold;
            font-size: 16px;
        }
    }
}