@import 'common/styles/theme/_theme.scss';

.paywall {
  @include grid-template;

  .paywall-details {
    .paywall-title {
      font-size: 20px;
      font-weight: $type-weight-bold;
      letter-spacing: -1px;
      margin-top: $space-s;
      margin-bottom: $space-xs;
      // color: $color-accent-primary;
    }

    .introductory-price {
      margin-bottom: 40px;

      .offer-price-wrapper {
        display: flex;
        flex-direction: column;
        gap: $space-xxs;

        .original {
          font-size: 13px;
          letter-spacing: -0.2px;
          text-decoration: line-through;
          text-decoration-color: $color-accent-primary;
          text-decoration-thickness: 2px;
          margin-bottom: -$space-xs;
        }

        .offer {
          .value {
            font-size: 32px;
            letter-spacing: -0.5px;
            font-weight: $type-weight-bold;
          }

          .metric {
            font-size: 15px;
          }
        }

        .offer-tag {
          font-family: $type-font-handwriting;
          font-size: 18px;
          color: $color-accent-primary;
        }
      }
    }

    .paywall-metadata {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .metadata-item {
        margin-bottom: 4px;

        .label {
          font-weight: $type-weight-bold;
          margin-right: 4px;
        }
      }
    }
  }
}

.payment-selection-modal {
  &.modal {
    .modal-body {
      margin-bottom: 0;
      margin-top: 30px;
      .button {
        margin: 10px;
        width: 220px;
        .helper-text {
          font-size: 12px;
          font-style: italic;
          opacity: 0.85;
        }
      }
    }
  }
}
