@import 'common/styles/theme/_theme.scss';

.article {
  @include section-wrapper;
  padding: 20px 0;
  padding-bottom: 60px;

  a {
    color: $color-accent-primary;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
    margin: 0;
  }
}
