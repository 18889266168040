@import 'common/styles/theme/_theme.scss';

.account-menu {
  ul.szh-menu {
    padding: unset;
    border-radius: 16px;
    color: $color-foreground;
    background-color: $color-elevation;
    box-shadow: -12px 12px 40px rgba($color-overlay, 0.2);
    overflow: hidden;

    li {
      margin: 0;

      &.szh-menu__item {
        padding: 12px 10px;
        margin: 4px 6px;
        border-radius: 12px;

        &:hover {
          background-color: $color-background;
        }
      }

      &.szh-menu__divider {
        padding: 0;
        margin: $space-xs 16px;
        background-color: $color-foreground;
        opacity: 0.16;
      }

      &.menu-item-last {
        margin-bottom: $space-xs;
      }
    }

    a {
      margin: 0;
      color: $color-foreground;

      &:hover {
        background-color: $color-background;
      }

      &.szh-menu__item {
        padding: 12px 10px;
        margin: 4px 6px;
        border-radius: 12px;
      }
    }
  }

  ul.menu-theme-selector {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;

    .menu-theme-options {
      display: flex;
      align-items: center;
      gap: $space-xxxs;

      li.menu-theme-item {
        position: relative;
        padding: 0;
        border: 1px solid transparent;
        transition: all 0.2s ease;

        &:before {
          content: none;
        }

        &:hover:not(.selected) {
          // border: 1px solid $color-positive-300;
          box-shadow: 0 0 24px rgba($color-accent-primary, 0.4);
          transform: scale(1.08, 1.08);
        }

        &.light,
        &.dark,
        &.black {
          display: block;
          width: 32px;
          height: 20px;
          border-radius: 4px;
        }

        &.light {
          background-color: $color-background-light;
        }

        &.dark {
          background-color: $color-background-dark;
        }

        &.black {
          background-color: $color-background-black;
        }

        &.selected {
          border: 1px solid $color-accent-primary;

          &:before {
            content: '✓';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $color-accent-primary;
            font-weight: $type-weight-medium;
          }
        }
      }
    }
  }

  .account-credits {
    padding: 6px 20px;
    margin-bottom: $space-xxs;
    font-size: 12px;
    font-weight: $type-weight-bold;
    background-color: rgba($color-accent-primary, 0.3);
    color: $color-foreground;
  }

  .menu-item-wrapper {
    display: flex;
    gap: $space-xs;
    align-items: center;
  }
}

.account-menu-button {
  padding: 12px 16px;
  border-radius: 16px;
  background-color: transparent;
  font-family: $type-font-primary;
  font-size: unset;
  font-weight: unset;
  color: $color-foreground;
  transition: all 0.4s ease;

  &:hover,
  &:focus {
    border: 1px solid $color-accent-primary;
    background-color: $color-elevation;
    color: $color-foreground;
    box-shadow: unset;
  }

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    vertical-align: middle;
    margin-left: $space-xxs;
  }

  @media screen and (max-width: 480px) {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
