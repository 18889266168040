@import '_colors.scss';
@import '_layout.scss';
@import '_type.scss';
@import '_motion.scss';
@import '_form.scss';

html,
body {
  height: 100%;
}

body {
  background: $color-background;
  color: $color-foreground;
  font-family: $type-font-primary;
  font-size: 14px;
}

img {
  width: 100%;
}

.icon {
  width: 20px;

  path {
    stroke: $color-foreground;
    // fill: $color-foreground;
  }
}

input,
textarea {
  color: $color-foreground;
  font-family: $type-font-primary;
}

a {
  color: $color-foreground;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: $color-accent-primary;
    cursor: pointer;
  }

  &.button {
    &:hover {
      color: var(--color-foreground);
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: $color-elevation;
  max-width: 320px;
  padding: 48px;
  border-radius: 24px;
  margin: 10px;

  .modal-title {
    padding: 0;
    margin: 0;
  }

  .modal-body {
    margin-bottom: 40px;
  }

  .divider {
    width: 100%;
    background-color: $color-foreground;
    height: 1px;
    opacity: 0.2;
    margin-bottom: 40px;
  }

  .modal-actions {
    button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px;
    .modal-actions {
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;
      column-gap: 10px;
      align-self: center;
      button {
        margin: 0;
        padding: 10px;
      }
    }
    .modal-body {
      margin-bottom: 16px;
    }
    .divider {
      margin-bottom: 16px;
    }
  }
}
