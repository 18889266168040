@import "common/styles/theme/_theme.scss";

.stripe-checkout-modal {
  max-width: unset !important;
  padding: $space-xxxl $space-xl $space-m !important;

  .modal-body {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 $space-xl $space-l;

    .section-title {
      font-size: 18px;
      font-weight: $type-weight-bold;
      color: $color-accent-primary;
      padding-left: $space-l;
    }
  }

  .stripe-address-form {
    border-radius: 16px;
    background-color: $color-background;
    padding: $space-m;
    margin-bottom: $space-xl;

    .form-element-row {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      margin-bottom: $space-m;

      .form-element-label {
        padding-left: $space-m;
        margin-bottom: $space-xs;
      }

      .form-element-item {

        input, select {
          width: 100%;
          // background-color: $color-background;
        }
      }
    }

    .note {
      display: block;
      font-size: 13px;
      line-height: 28px;
      margin-top: $space-xl;
      background-color: $color-warning-300;
      color: $color-warning-100;
      border-radius: 16px;
    }
  }

  #payment-form {

    #payment-element {
      background-color: $color-background;
      padding: $space-l $space-m;
      border-radius: 16px;
      margin-bottom: $space-l;
    }
  }
}
