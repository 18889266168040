@import 'common/styles/theme/_theme.scss';

.upload-recording {
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 50px; // to maintain consistency with Dashboard
    padding: 0 16px;
    margin-bottom: 56px;

    .title {
      font-weight: $type-weight-bold;
      font-size: 24px;
      letter-spacing: -0.4px;
    }

    .cancel-button {
      display: flex;
      align-items: center;

      &:hover {
        .cross-icon {
          path {
            stroke: $color-accent-primary;
          }
        }
      }

      .label {
        line-height: 24px;
        letter-spacing: 0.4px;
        margin-right: 4px;
      }

      .cross-icon {
        path {
          transition: all 0.4s ease;
        }
      }
    }
  }

  .upload-box {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    text-align: center;
    background-color: $color-elevation;
    border: 1px dashed $color-accent-primary;
    border-radius: 24px;
    padding: 48px 32px 32px;
    margin: 0 16px 40px;
    box-sizing: border-box;

    .dropzone-container {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .audio-file-icon {
        width: 100%;
        opacity: 0.56;

        path {
          stroke: none;
        }
      }

      .description {
        font-weight: $type-weight-bold;
        font-size: 16px;
        line-height: 24px;

        a {
          color: $color-accent-primary;
          cursor: pointer;
          transition: all 0.4s ease;

          &:hover {
            color: $color-accent-secondary;
          }
        }
      }

      .formats {
        color: $color-foreground;
        opacity: 0.56;
      }
    }

    .progress-wrapper {
      width: 100%;

      .progress-bar {
        width: 100%;
        height: 8px;
        margin-bottom: 12px;
        background-color: $color-foreground;
        border-radius: 24px;
        overflow: hidden;

        .completed {
          height: 100%;
          background: $color-accent-primary;
          background: linear-gradient(
            140deg,
            rgba(205, 121, 121, 1) 0%,
            rgba(228, 170, 170, 1) 100%
          );
          transition: all 0.6s ease;
        }
      }

      .progress-metadata {
        display: flex;
        justify-content: space-between;

        .file-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 4px;

          .file-name {
            font-weight: $type-weight-bold;
            line-height: 20px;
          }
        }

        .percentage {
          font-size: 32px;
          line-height: 32px;
          letter-spacing: -0.4px;
          font-weight: $type-weight-medium;
        }
      }
    }

    .upload-metadata {
      display: grid;
      grid-template-columns: auto 120px 150px;
      column-gap: 24px;
      row-gap: 12px;
      width: 100%;

      .title,
      .speakers,
      .language {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          padding-left: 8px;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          background-color: $color-background;
        }
      }

      .language {
        select {
          width: 100%;
          background-color: $color-background;
        }
      }

      .audio-lang-note {
        display: flex;
        width: auto;
        padding: $space-xxs $space-xs;
        font-size: 12px;
        background-color: $color-warning-300;
        color: $color-warning-100;
        border-radius: 16px;
      }
    }
  }

  .call-to-action {
    text-align: center;
  }

  .validation-error {
    padding: 20px;
    color: $color-negative-200;
    padding-top: 0;
  }

  @media screen and (max-width: 480px) {
    .controls {
      margin: 0;
    }

    .upload-box {
      margin: 20px 0;
      padding: 16px;

      .upload-metadata {
        grid-template-columns: initial;
        row-gap: 12px;
      }

      .progress-wrapper {
        .progress-metadata {
          flex-direction: column-reverse;
          text-align: left;
          gap: 18px;
        }
      }
    }

    .call-to-action {
      margin-bottom: 32px;
    }
  }
}
