@import 'common/styles/theme/_theme.scss';

.login-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  width: 464px;
  max-width: $container-max-width;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 20px;

  .login-title {
    font-size: 32px;
    letter-spacing: -0.56px;
    font-weight: $type-weight-bold;
  }

  .login-options {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 32px;
    background-color: $color-elevation;
    border-radius: 24px;
    max-width: 400px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
    row-gap: 16px;
    .login-title {
      font-size: 24px;
    }
    .login-options {
      padding: 16px;
    }
  }
}

.login-note {
  display: block;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  width: 464px;
  box-sizing: border-box;
  padding: $space-xs $space-m;
  margin: $space-l auto 0;
  background-color: $color-warning-300;
  color: $color-warning-100;
  border-radius: 16px;
  max-width: $container-max-width;
  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 12px;
    padding: $space-xs;
  }
}
