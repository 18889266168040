@import 'common/styles/theme/_colors.css';

/* Background */

$color-background-gradient: var(--color-background-gradient);
$color-background: var(--color-background);

/* Foreground */

$color-foreground: var(--color-foreground);

/* Elevation */

$color-elevation: var(--color-elevation);

/* Social Buttons */

$google-button: var(--google-button);
$google-button-text: var(--google-button-text);

/* Alerts */

$color-positive-200: var(--color-positive-200);
$color-positive-100: var(--color-positive-100);
$color-positive-300: var(--color-positive-300);

$color-negative-200: var(--color-negative-200);
$color-negative-100: var(--color-negative-100);
$color-negative-300: var(--color-negative-300);

$color-warning-200: var(--color-warning-200);
$color-warning-100: var(--color-warning-100);
$color-warning-300: var(--color-warning-300);

/* Accents */

$color-accent-primary: #cd7979;
$color-accent-secondary: #ffc279;

$color-overlay: rgba(0, 0, 0, 0.65);

$color-disabled-button: grey;

/* Static Colors */
$color-background-light: #F3EDF3;
$color-background-dark: #343037;
$color-background-black: #242424;

$color-light: #F3EDF3;
$color-dark: #343037;

[data-theme="light"] {

  img {

    &.illustration {

      &.monochromatic {
        filter: invert(1);

        &.accent {
          filter: brightness(0.8);
        }
      }
    }
  }
}
