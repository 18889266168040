@import 'common/styles/theme/_theme.scss';

.landing-page {
  width: 100vw;
  height: 100vh;
  margin-top: -80px;
  background: url('/assets/images/landing-page-bg-dark.png') center 20% / cover no-repeat;

  .landing-page-wrapper {
    @include section-wrapper;
    height: 100%;
    padding-left: 16px;
    padding-bottom: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 112px;

    .hero {
      .eyebrow {
        display: none;
        width: max-content;
        font-size: 14px;
        padding: 10px 16px;
        border-radius: 64px;
        background-color: $color-background;
        margin-bottom: 24px;
      }

      .heading {
        font-size: 48px;
        line-height: 56px;
        font-weight: $type-weight-bold;
        letter-spacing: -1.5px;
        margin: 0 0 32px 0;
      }

      .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 56px;
        max-width: 50%;
      }

      .call-to-action {
        display: flex;
        align-items: center;
        gap: 16px;

        .cta-meta {
          display: flex;
          flex-direction: column;

          .offer {
            font-size: 16px;
            font-weight: $type-weight-bold;
            letter-spacing: -0.56px;
          }

          .default {
            font-size: 13px;
          }
        }

        .button {
          text-align: center;
          .button-helper {
            font-size: 11px;
          }
        }
      }
    }

    footer {
      display: flex;
      gap: 24px;

      .footer-link {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    background: none;
    width: 100%;
    .landing-page-wrapper {
      padding: 0;
      gap: 32px;
      justify-content: center;

      .hero {
        .heading {
          font-size: 36px;
        }
        .description {
          max-width: none;
          margin-bottom: 36px;
        }
        .call-to-action {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

[data-theme='light'] {
  .landing-page {
    background: url('/assets/images/landing-page-bg-light.png') center 20% / cover no-repeat;
  }
  @media screen and (max-width: 480px) {
    .landing-page {
      background: none;
    }
  }
}
