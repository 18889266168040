@import 'common/styles/theme/_theme.scss';

.transcript {
  padding-bottom: 200px;

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 50px; // to maintain consistency with Dashboard
    padding: 0 32px;
    margin-bottom: 56px;

    .back-button {
      display: flex;
      align-items: center;

      &:hover {
        .back-icon {
          path {
            stroke: $color-accent-primary;
          }
        }
      }

      .label {
        line-height: 24px;
        letter-spacing: 0.4px;
        margin-left: 8px;
      }

      .back-icon {
        path {
          transition: all 0.4s ease;
        }
      }
    }
  }

  .transcript-content {
    display: flex;
    flex-direction: column;

    .details {
      box-sizing: border-box;
      padding: 0 32px;
      margin-bottom: 80px;

      .title {
        font-size: 80px;
        line-height: 96px;
        letter-spacing: -4px;
        max-width: 640px;
        margin-left: -3px;
        margin-bottom: 40px;
      }

      .information {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .metadata {
          display: flex;
          flex-direction: row;
          column-gap: 12px;
          line-height: 24px;
        }

        .actions {
          display: flex;
          gap: $space-l;
          line-height: 24px;

          .action-item {
            display: flex;
            align-items: center;
            vertical-align: middle;
            cursor: pointer;

            .icon {
              width: 20px;
              margin-right: 4px;

              path {
                transition: stroke 0.3s ease;
              }
            }

            .star-filled-icon {
              path {
                fill: $color-accent-secondary;
                stroke: $color-accent-secondary;
              }
            }

            .archive-filled-icon path {
              &:first-child {
                fill: $color-accent-secondary;
                stroke: $color-accent-primary;
              }
              &:last-child {
                fill: $color-accent-secondary;
                stroke: $color-accent-primary;
              }
            }

            span {
              color: $color-foreground;
              font-weight: $type-weight-bold;
              font-size: 13px;
              text-transform: uppercase;
              transition: color 0.3s ease;
            }

            &:hover {
              .icon {
                path {
                  stroke: $color-accent-primary;
                }
              }

              .star-filled-icon {
                path {
                  fill: $color-accent-primary;
                  stroke: $color-accent-primary;
                }
              }

              .archive-filled-icon path {
                &:first-child {
                  fill: $color-accent-primary;
                  stroke: $color-accent-secondary;
                }
                &:last-child {
                  fill: $color-accent-primary;
                  stroke: $color-accent-secondary;
                }
              }

              span {
                color: $color-accent-primary;
              }
            }
          }
        }
      }
    }

    .summary {
      box-sizing: border-box;
      background-color: $color-elevation;
      border-radius: 24px;
      padding: 32px;
      margin-bottom: 12px;

      .summary-copy {
        margin: 0;
        line-height: 26px;
      }
    }

    .transcription-copy {
      padding: $space-xxxl 0;

      .title-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 0 0 $space-xxxl;
        cursor: default;

        .edit-helper {
          font-size: 12px;
          line-height: 24px;
          font-weight: $type-weight-medium;
          padding: 0 $space-xs;
          border-radius: 16px;
          background-color: $color-warning-300;
          color: $color-warning-100;
        }

        .section-title {
          font-weight: $type-weight-bold;
          font-size: 24px;
          letter-spacing: -0.6px;
          margin-bottom: 32px;
        }

        .button-container {
          display: flex;
          column-gap: 10px;
          flex-direction: row;

          .button {
            font-size: 14px;
            padding: 8px 20px;
            border-radius: 8px;
            height: fit-content;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 0;

    .controls {
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }

    .transcript-content {
      .details {
        padding: 0 8px;
        margin-bottom: 24px;

        .title {
          font-size: 42px;
          letter-spacing: unset;
          line-height: unset;
          margin-bottom: 12px;
        }

        .information {
          flex-direction: column;
          align-items: flex-end;
          row-gap: 8px;

          .metadata {
            line-height: 16px;
          }

          .actions {
            gap: 0;
            line-height: 16px;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .action-item {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }

      .transcription-copy {
        padding: 16px;
        .title-button-wrapper {
          flex-direction: column;
          margin-bottom: 20px;
          row-gap: 10px;
          padding: 0;

          .section-title {
            margin-bottom: 0;
          }

          .button-container {
            align-self: end;
          }
        }
      }
    }
  }
}
