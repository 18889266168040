$space-xxxs: 2px;
$space-xxs: 4px;
$space-xs: 8px;
$space-s: 12px;
$space-m: 16px;
$space-l: 20px;
$space-xl: 24px;
$space-xxl: 28px;
$space-xxxl: 32px;

$page-max-width: 1200px;
$container-max-width: 600px;

@mixin section-wrapper {
    margin: 0 auto;
    max-width: $page-max-width;
}

@mixin grid-template {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 40px;
    align-items: center;
    box-sizing: border-box;
    background-color: $color-elevation;
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 80px;

    .illustration {
        width: 400px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: auto;

        .illustration {
            width: 240px;
        }
    }
}