@import 'common/styles/theme/_colors.css';

label {
  font-weight: $type-weight-bold;
}

input, textarea, select {
  font-size: 16px;
  padding: 14px 16px;
  background-color: $color-elevation;
  color: $color-foreground;
  box-sizing: border-box;
  outline: none;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: all 0.4s ease;

  &:focus {
    border: 1px solid $color-accent-primary;
    box-shadow: 0 0 24px rgba($color-accent-primary, 0.4);
  }

  &:-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    color: $color-accent-primary;
  }
}

button,
a.button {
  font-size: 16px;
  letter-spacing: -0.2px;
  font-weight: $type-weight-bold;
  padding: 14px 32px;
  background-color: $color-elevation;
  color: $color-light;
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  border-radius: 12px;
  transition: all 0.4s ease;

  &:hover,
  &:focus {
    border: 1px solid $color-accent-primary;
    box-shadow: 0 0 24px rgba($color-accent-primary, 0.4);
  }

  &.primary {
    background-color: $color-accent-primary;

    &:hover,
    &:focus {
      color: $color-light;
      border: 1px solid $color-dark;
      box-shadow: 0 0 24px rgba($color-accent-primary, 0.4);
      cursor: pointer;
    }
  }

  &.secondary {
    background-color: $color-accent-secondary;
    color: $color-background;
  }

  &.tertiary {
    background-color: $color-foreground;
    color: $color-background;
  }

  &:disabled {
    background-color: $color-disabled-button;
  }
}

a.button {
  width: max-content;
}
