@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

$type-font-primary: "Manrope",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;

$type-font-handwriting: "Gochi Hand", cursive;

$type-weight-regular: 400;
$type-weight-medium: 500;
$type-weight-bold: 700;
