@import 'common/styles/theme/_theme.scss';

.cookie-consent-modal {
  &.modal {
    max-width: 100%;

    .modal-title {
      font-size: 32px;
      font-weight: $type-weight-bold;
    }

    .modal-body {
      .modal-body-title {
        font-size: 16px;
        padding: 20px 0;
      }

      .cookie-consent-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        background-color: $color-background;
        border-radius: 16px;
        padding: $space-m;

        .checkbox-item {
          padding: $space-xs;
          font-weight: $type-weight-regular;

          input {
            cursor: pointer;
            vertical-align: bottom;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      .modal-body {
        .cookie-consent-options {
          padding: $space-xs;
          .checkbox-item {
            display: inline-block;
            text-align: left;
          }
        }
      }
      .modal-title {
        font-size: 28px;
      }
    }
  }
}
