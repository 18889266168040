@import 'common/styles/theme/_theme.scss';

.authentication-container {
  .authentication {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-m;
    border: 1px solid transparent;
    border-radius: 16px;
    background-color: $color-background;
    transition: all 0.4s ease;
    cursor: pointer;

    &.disabled {
      display: none;
    }

    &:hover,
    &:focus {
      border-color: $color-accent-primary;
      box-shadow: 0 0 24px rgba($color-accent-primary, 0.4);
    }

    .label {
      font-size: 16px;
    }

    .auth-logo {
      width: 20px;
      height: 20px;
      margin-right: $space-s;
    }
  }

  .or-separator {
    position: relative;
    margin: $space-l auto;
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: $type-weight-bold;
    opacity: 0.6;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 45%;
      height: 1px;
      background-color: $color-foreground;
      opacity: 0.2;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .email-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-l;
    background-color: $color-background;
    border-radius: $space-m;
    padding: $space-l;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $space-xs;
      width: 100%;

      label {
        display: none;
        padding-left: $space-m;
      }

      input {
        width: 100%;
      }
    }

    .authentication-submit-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $space-xxxl;
      margin-top: $space-s;

      .authentication-terms {
        font-size: 11px;
        letter-spacing: 0.1px;
        line-height: 16px;
        text-align: left;

        a {
          text-decoration: underline;

          &:hover {
            color: $color-accent-primary;
          }
        }
      }

      .authentication-submit {
        white-space: nowrap;
        width: max-content;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .email-password-container {
      gap: 8px;
      .authentication-submit-wrapper {
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}
