.translate-container {
  display: flex;
  margin-bottom: 30px;
  .source-language,
  .destination-language {
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    label {
      margin: 5px 0;
    }
  }
  .translate-button {
    display: flex;
    align-self: flex-end;
    margin: 0 10px;
  }
}
